import React, { useState, useEffect, useCallback, useRef } from "react";
import { ForceGraph2D } from "react-force-graph";
import { Helmet } from "react-helmet";
import "./WordRelationshipGame.css";

const checkConnectivity = (graph, start, end, visited = new Set()) => {
  if (start === end) return true;
  visited.add(start);
  for (const neighbor of graph[start] || []) {
    if (!visited.has(neighbor) && checkConnectivity(graph, neighbor, end, visited)) {
      return true;
    }
  }
  return false;
};

const WordRelationshipGame = () => {
  const [words, setWords] = useState([]);
  const [initialWords, setInitialWords] = useState([]);
  const [newWord, setNewWord] = useState("");
  const [relationships, setRelationships] = useState({});
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [graphData, setGraphData] = useState({ nodes: [], links: [] });
  const [selectedWord, setSelectedWord] = useState(null);
  const [message, setMessage] = useState("");
  const [showRules, setShowRules] = useState(false);
  const [gameEnded, setGameEnded] = useState(false);
  const [showEndGameModal, setShowEndGameModal] = useState(false);
  const inputRef = useRef(null);
  const fgRef = useRef();

  const handleNodeCollision = useCallback(() => {
    if (!fgRef.current) return;

    const fg = fgRef.current;
    const simulation = fg.d3Force();
    if (!simulation) return;

    const nodes = simulation.nodes();
    const nodeRadius = 50;

    nodes.forEach(node => {
      node.collided = false;
    });

    for (let i = 0; i < nodes.length; i++) {
      for (let j = i + 1; j < nodes.length; j++) {
        const dx = nodes[i].x - nodes[j].x;
        const dy = nodes[i].y - nodes[j].y;
        const distance = Math.sqrt(dx * dx + dy * dy);

        if (distance < nodeRadius * 2) {
          const angle = Math.atan2(dy, dx);
          const overlap = nodeRadius * 2 - distance;

          nodes[i].x += Math.cos(angle) * overlap / 2;
          nodes[i].y += Math.sin(angle) * overlap / 2;
          nodes[j].x -= Math.cos(angle) * overlap / 2;
          nodes[j].y -= Math.sin(angle) * overlap / 2;

          nodes[i].collided = true;
          nodes[j].collided = true;
        }
      }
    }

    fg.refresh();
  }, []);

  const updateGraphData = useCallback(() => {
    const nodes = words.map((word) => ({ 
      id: word, 
      label: word,
      isInitial: initialWords.includes(word)
    }));
    const links = Object.entries(relationships)
      .filter(([_, value]) => value >= 0.38)
      .map(([key, value]) => {
        const [source, target] = key.split("-");
        return { source, target, value };
      });
    
    setGraphData({ nodes, links });

    const graph = {};
    links.forEach(({ source, target }) => {
      if (!graph[source]) graph[source] = [];
      if (!graph[target]) graph[target] = [];
      graph[source].push(target);
      graph[target].push(source);
    });

    if (initialWords.length === 2 && words.length > 2 && checkConnectivity(graph, initialWords[0], initialWords[1])) {
      setGameEnded(true);
      setShowEndGameModal(true);
    }
  }, [words, relationships, initialWords]);

  useEffect(() => {
    updateGraphData();
  }, [updateGraphData]);

  const initializeGame = async () => {
    setIsLoading(true);
    try {
      const response = await fetch("https://www.meiotermo.com/api/initialize-game");
      if (!response.ok) throw new Error("Falha ao inicializar o jogo");
      const data = await response.json();
      setWords(data.words);
      setInitialWords(data.words);
      setRelationships(data.relationships);
      setGameEnded(false);
      setShowEndGameModal(false);
      setNewWord("");
      setSelectedWord(null);
      setMessage("");
      setError(null);
    } catch (err) {
      setError("Falha ao inicializar o jogo. Por favor, tente novamente.");
    } finally {
      setIsLoading(false);
    }
  };

  const addWord = async (wordToAdd) => {
    if (wordToAdd && !gameEnded) {
      setIsLoading(true);
      try {
        const response = await fetch("https://www.meiotermo.com/api/add-word", {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({ newWord: wordToAdd, currentWords: words }),
        });
        const data = await response.json();
        if (!response.ok) {
          setMessage(data.message);
          setTimeout(() => setMessage(""), 3000);
        } else {
          setWords(data.words);
          setRelationships((prevRelationships) => ({
            ...prevRelationships,
            ...data.relationships,
          }));
          setNewWord("");
          setError(null);
        }
      } catch (err) {
        setError("Falha ao adicionar palavra. Por favor, tente novamente.");
      } finally {
        setIsLoading(false);
      }
    }
  };

  const handleNodeClick = useCallback((node) => {
    setSelectedWord(node.id);
  }, []);

  const getWordRelationships = useCallback((word) => {
    return Object.entries(relationships)
      .filter(([key]) => key.includes(word))
      .map(([key, value]) => {
        const [word1, word2] = key.split("-");
        const otherWord = word1 === word ? word2 : word1;
        return { word: otherWord, strength: value };
      });
  }, [relationships]);

  const handleInputChange = (e) => {
    setNewWord(e.target.value);
    setMessage("");
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter" && newWord && !gameEnded) {
      addWord(newWord);
    }
  };

  const getFilteredRelationships = useCallback(() => {
    const allRelationships = Object.entries(relationships);
    const strongRelationships = allRelationships.filter(
      ([_, value]) => value >= 0.38
    );

    if (allRelationships.length > strongRelationships.length) {
      const weakestRelationship = allRelationships.reduce((min, current) =>
        current[1] < min[1] ? current : min
      );
      return [...strongRelationships, weakestRelationship];
    }

    return strongRelationships;
  }, [relationships]);

  const toggleRules = () => setShowRules(!showRules);
  const closeEndGameModal = () => setShowEndGameModal(false);

  useEffect(() => {
    initializeGame();
  }, []);

  return (
    <>
      <Helmet>
        <title>Meio-Termo - Jogo de Relacionamento de Palavras</title>
        <meta name="description" content="Jogue Meio-Termo, um jogo divertido de relacionamento de palavras em português. Descubra conexões entre palavras e expanda seu vocabulário!" />
        <meta name="keywords" content="meio-termo, jogo de palavras, português, vocabulário, relacionamento de palavras" />
      </Helmet>
      <div className="game-container">
        <h1 className="game-title">Meio-Termo</h1>
        <div className="button-container">
          <button onClick={toggleRules} className="rules-button">
            {showRules ? "Fechar Regras" : "Como Jogar"}
          </button>
          <button onClick={initializeGame} className="new-game-button">
            Novo Jogo
          </button>
        </div>
        {showRules && (
          <div className="rules-modal">
            <h2>Como Jogar</h2>
            <ol>
              <li>O jogo começa com duas palavras aleatórias.</li>
              <li>Seu objetivo é adicionar novas palavras e criar conexões entre elas.</li>
              <li>Digite uma nova palavra no campo de entrada e pressione "Adicionar" ou Enter.</li>
              <li>As palavras devem ter entre 3 e 15 letras.</li>
              <li>O jogo calculará automaticamente o relacionamento entre a nova palavra e as existentes.</li>
              <li>Relacionamentos com força igual ou superior a 38% são considerados significativos e serão exibidos como conexões no gráfico.</li>
              <li>Você pode ver todos os relacionamentos significativos na lista abaixo do gráfico.</li>
              <li>Clique em uma palavra no gráfico para ver seus relacionamentos específicos.</li>
              <li>Palavras ofensivas ou inapropriadas não são permitidas no jogo.</li>
              <li>O jogo termina quando você consegue conectar as duas palavras iniciais através de outras palavras.</li>
              <li>Divirta-se explorando as conexões entre as palavras e expandindo sua rede de vocabulário!</li>
            </ol>
          </div>
        )}
        {error && <div className="error-message">{error}</div>}
        {message && <div className="message">{message}</div>}
        <div className="input-container">
          <div className="input-wrapper">
            <input
              ref={inputRef}
              type="text"
              value={newWord}
              onChange={handleInputChange}
              onKeyPress={handleKeyPress}
              placeholder="Digite uma nova palavra"
              className="word-input"
              disabled={isLoading || gameEnded}
            />
          </div>
          <button
            onClick={() => addWord(newWord)}
            className="add-button"
            disabled={isLoading || gameEnded}
          >
            {isLoading ? "Adicionando..." : "Adicionar"}
          </button>
        </div>
        <div className="graph-container">
        <ForceGraph2D
          ref={fgRef}
          graphData={graphData}
          nodeLabel="label"
          nodeColor={(node) => (node.id === selectedWord ? "red" : node.collided ? "#ff9800" : "#3498db")}
          linkColor="#0605FE"
          linkWidth={(link) => link.value * 5}
          onNodeClick={handleNodeClick}
          nodeCanvasObject={(node, ctx, globalScale) => {
              const label = node.label;
              const fontSize = 16 / globalScale;
              ctx.font = `${fontSize}px Sans-Serif`;
              const textWidth = ctx.measureText(label).width;
              const bckgDimensions = [textWidth, fontSize].map(
                (n) => n + fontSize * 0.2
              );

              ctx.fillStyle = "rgba(255, 255, 255, 0.8)";
              ctx.fillRect(
                node.x - bckgDimensions[0] / 2,
                node.y - bckgDimensions[1] / 2,
                ...bckgDimensions
              );

              if (node.isInitial) {
                const circleRadius = Math.max(fontSize * 1.2, textWidth / 2 + fontSize * 0.4);
                ctx.beginPath();
                ctx.arc(node.x, node.y, circleRadius, 0, 2 * Math.PI);
                ctx.strokeStyle = "#ff9800";
                ctx.lineWidth = 2;
                ctx.stroke();
              }

              ctx.textAlign = "center";
              ctx.textBaseline = "middle";
              ctx.fillStyle = node.id === selectedWord ? "red" : "black";
              ctx.font = `bold ${fontSize}px Sans-Serif`;
              ctx.fillText(label, node.x, node.y);

              node.__bckgDimensions = bckgDimensions;
            }}
            nodePointerAreaPaint={(node, color, ctx) => {
              ctx.fillStyle = color;
              const bckgDimensions = node.__bckgDimensions;
              bckgDimensions &&
                ctx.fillRect(
                  node.x - bckgDimensions[0] / 2,
                  node.y - bckgDimensions[1] / 2,
                  ...bckgDimensions
                );
            }}
            width={800}
            height={500}
            d3Force={{
              link: { distance: 400 },
              charge: { strength: -5000 },
              collide: { strength: 1, radius: 100 }
            }}
            linkCurvature={0.25}
            linkDirectionalParticles={2}
            linkDirectionalParticleSpeed={0.005}
            onEngineStop={handleNodeCollision}
          />
        </div>
        {selectedWord && (
          <div className="selected-word-info">
            <h2>Palavra Selecionada: {selectedWord}</h2>
            <h3>Relacionamentos:</h3>
            <ul>
              {getWordRelationships(selectedWord).map(({ word, strength }) => (
                <li
                  key={word}
                  className={
                    strength >= 0.38 ? "strong-relationship" : "weak-relationship"
                  }
                >
                  {word}: {(strength * 100).toFixed(2)}%
                </li>
              ))}
            </ul>
          </div>
        )}
        <div className="relationships-container">
          <h2>Relacionamentos:</h2>
          <ul className="relationship-list">
            {getFilteredRelationships().map(([key, value]) => (
              <li
                key={key}
                className={`relationship-item ${
                  value >= 0.38 ? "strong-relationship" : "weak-relationship"
                }`}
              >
                <span className="relationship-words">
                  {key.replace("-", " - ")}
                </span>
                <span className="relationship-value">
                  {(value * 100).toFixed(2)}%
                </span>
              </li>
            ))}
          </ul>
        </div>
        {showEndGameModal && gameEnded && words.length > 2 && (
          <div className="end-game-modal">
            <button onClick={closeEndGameModal} className="close-modal-button">&times;</button>
            <h2>Você ganhou!</h2>
            <p>Você adicionou {words.length - 2} palavras para conectar as palavras iniciais.</p>
            <button onClick={() => { initializeGame(); closeEndGameModal(); }} className="new-game-button">
              Novo Jogo
            </button>
          </div>
        )}
      </div>
    </>
  );
};

export default WordRelationshipGame;